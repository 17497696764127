import { I18N } from "aurelia-i18n";
import { WarehouseService } from "services/client/warehouse-service";
import { IWarehouse } from "domain/Client/IWarehouse";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager, autoinject, bindable } from "aurelia-framework";
import { ICart } from "domain/Cart/ICart";
import { IProduct } from "domain/Product/IProduct";
import { CartProductService } from "services/cart/cart-product-service";
import { ICartProduct } from "domain/Cart/ICartProduct";

export const log = LogManager.getLogger("app.cart.index");

@autoinject
export class TransportListCustomElement {
  @bindable allowedTransportOptions: Map<string, string>;
  @bindable warehouses: IWarehouse[];
  userSpecifiedAddresses: string[];
  combinedWarehouses = [1, 3];

  @bindable products: ICartProduct[];
  error: string;

  // map contents: (warehouseId; transportId)
  selectedTransportsForWarehouses = new Map<number, string>();

  @bindable comment = "";
  selectedTransportAddress = "";

  totalWithoutVat = 0;
  totalVat = 0;
  totalWithVat = 0;

  options = {
    showImages: true,
    showEan: true,
    showStock: true,
    showWarehouse: true,
    showReason: false,
    showDisplayPrice: true,
  };

  constructor(
    private cartProductService: CartProductService,
    private warehouseService: WarehouseService,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig,
    private i18n: I18N
  ) {
    this.eventAggregator.subscribe(TKEvent.cartProductsChanged, () => {
      this.updateSelectedTransports();
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsWarehouseChanged, (product: IProduct) => {
      this.updateCartProductWarehouseId(product);
      this.updateSelectedTransports();
    });
  }

  attached() {
    this.updateSelectedTransports();
  }

  // #region OBSERVABLES, LISTENERS

  selectedTransportsForWarehousesChanged(warehouseId: number, transportId: string) {
    this.selectedTransportsForWarehouses.set(warehouseId, transportId);

    if (
      this.combinationWarehouseNeeded(this.products) &&
      warehouseId == this.appConfig.userSettings.warehouseId
    ) {
      this.selectedTransportsForWarehouses.set(
        this.combinationOtherWarehouseId() ?? 0,
        transportId
      );
    }

    if (!this.products) return;

    const isExternalWarehouse = warehouseId == -1;

    this.products
      .filter(
        x =>
          (isExternalWarehouse && x.externalWarehouseId) ||
          x.warehouseId == warehouseId ||
          (this.combinationWarehouseNeeded(this.products) &&
            x.warehouseId == this.combinationOtherWarehouseId())
      )
      .forEach(product => {
        if (product.transportId == transportId) return;

        product.transportId = transportId;
        this.updateSelectedTransports();

        // Send changed product to BE
        const cartProduct = {
          cartProductId: product.cartProductId ?? 0,
          foundBy: product.foundBy,
          code: product.code,
          quantity: parseInt(product.quantity.toString()),
          comment: product.comment,
          warehouseId: product.selectedStock?.warehouse?.id ?? product.warehouseId ?? 0,
          transportId: parseInt(product.transportId ?? "0"),
        } as any;

        if (product.selectedStock?.warehouse?.isExternal) {
          cartProduct.warehouseId = 0;
          cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
        }

        this.cartProductService
          .putExistingProduct(cartProduct)
          .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.update")));
      });
  }

  updateSelectedTransports() {
    if (!this.products) return;

    this.selectedTransportsForWarehouses = new Map<number, string>();

    for (const p of this.products) {
      if (p.externalWarehouseId) {
        this.selectedTransportsForWarehouses.set(-1, p.transportId ?? "0");
      } else {
        if (p.warehouseId == this.appConfig.userSettings.warehouseId) {
          this.selectedTransportsForWarehouses.set(p.warehouseId ?? 0, p.transportId ?? "0");
        } else {
          this.selectedTransportsForWarehouses.set(p.warehouseId ?? 0, "802");
        }
      }
    }
  }

  updateCartProductWarehouseId(product: IProduct) {
    const cartProduct = this.products.find(p => p.id == product.id);
    if (!cartProduct) return;

    if (!product.selectedStock?.warehouse?.isExternal) {
      cartProduct.externalWarehouseId = undefined;
      cartProduct.warehouseId = product.selectedStock?.warehouse?.id;
    } else {
      cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
      cartProduct.warehouseId = 0;
    }
    this.preselectOnlyTransportOption();
  }

  // #endregion

  // #region HELPERS

  getWarehouseName(warehouseId: number): string {
    if (warehouseId == -1) return this.i18n.tr("components.products.headers.partner-warehouse");

    if (
      this.combinationWarehouseNeeded(this.products) &&
      this.combinedWarehouses.includes(warehouseId)
    ) {
      return "Keskladu/Mustamäe";
    }

    if (this.warehouses != undefined) {
      for (const warehouse of this.warehouses) {
        if (warehouse.id == warehouseId) return warehouse.name;
      }
    }

    return `ID: ${warehouseId}`;
  }

  getTransportName(transportId: string): string {
    return this.allowedTransportOptions.get(transportId) ?? "0";
  }

  anyExternalWarehouseProducts() {
    return this.products.some(x => !!x.externalWarehouseId);
  }

  preselectOnlyTransportOption() {
    if (this.allowedTransportOptions.size != 1) return;

    const transportId = this.allowedTransportOptions.keys().next().value;

    this.products.forEach(product => {
      if (product.transportId == transportId) return;

      product.transportId = transportId;
      if (!product.externalWarehouseId) {
        this.selectedTransportsForWarehouses.set(
          product.warehouseId ?? 0,
          product.transportId ?? "0"
        );
      }
    });
  }

  canChangeTransport(warehouseId: number) {
    return warehouseId == -1 || warehouseId == this.appConfig.userSettings.warehouseId;
  }

  combinationWarehouseNeeded(products: ICartProduct[]) {
    const hasCentralWarehouse = products.some(p => p.warehouseId === 1);
    const hasMustamae = products.some(p => p.warehouseId === 3);

    return hasCentralWarehouse && hasMustamae;
  }

  combinationOtherWarehouseId() {
    if (this.appConfig.userSettings.warehouseId == this.combinedWarehouses[0]) {
      return this.combinedWarehouses[1];
    }
    if (this.appConfig.userSettings.warehouseId == this.combinedWarehouses[1]) {
      return this.combinedWarehouses[0];
    }
  }

  shouldDisplayInList(warehouseId: number) {
    return !this.combinationWarehouseNeeded(this.products) || warehouseId !== this.combinationOtherWarehouseId();
  }

  //#endregion
}
