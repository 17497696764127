import { I18N } from "aurelia-i18n";
import { IWarehouse } from "domain/Client/IWarehouse";
import { WarehouseService } from "services/client/warehouse-service";
import { ICartProduct } from "domain/Cart/ICartProduct";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager, autoinject, observable, bindable } from "aurelia-framework";
import { IProduct } from "domain/Product/IProduct";
import { CartProductService } from "services/cart/cart-product-service";
import { ProductService } from "services/product/product-service";
import { PriceHelper } from "utils/helpers/price-helper";
import { TKEvent } from "utils/enums/TKEvent";
import $ from "jquery";
import "bootstrap";
import { Modal, Popover } from "bootstrap";

export const log = LogManager.getLogger("app.components.product-list");

@autoinject
export class CartProductListCustomElement {
  @bindable @observable products: ICartProduct[];
  @bindable warehouses: IWarehouse[];

  @bindable options = {
    showImages: false,
    showEan: false,
    showStock: false,
    showWarehouse: false,
    showReason: false,
    showDisplayPrice: false,
  };

  imagesLoaded = false;

  constructor(
    private cartProductService: CartProductService,
    private productService: ProductService,
    private warehouseService: WarehouseService,
    private appConfig: AppConfig,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.formatPrice();
      this.tryLoadImages();
    });
    this.eventAggregator.subscribe(
      TKEvent.cartProductsWarehouseChanged,
      (product: ICartProduct) => {
        this.updateProduct(product);
      },
    );
  }

  attached() {
    // options are populated now
    this.loadWarehouses();
    this.tryLoadImages();
  }

  // #region MAIN

  productsChanged() {
    this.imagesLoaded = false;
    this.processResult();
  }

  processResult() {
    Utils.loadSortable();

    this.formatPrice();
    this.tryLoadImages();

    this.products.forEach(product => {
      this.loadExternalStock(product);
    });
  }

  // #endregion

  // #region LOAD

  loadWarehouses() {
    this.warehouseService
      .getWarehouses()
      .then(result => (this.warehouses = result))
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("components.warehouses.errors.load")));
  }

  loadThumbnail(product: IProduct) {
    this.productService
      .fetchThumbnail(product.id)
      .then(result => {
        product.thumbnail = result;

        if (result == "") return;

        // Large thumbnail when hovering
        const thumbnailList = document.querySelector(`[data-image="${product.id}"]`);
        if (thumbnailList) {
          new Popover(thumbnailList, {
            content: `<img src="${product.thumbnail}"></img>`,
            trigger: "hover focus",
            container: $(`[data-image="${product.id}"]`).get(0),
            html: true,
          });
        }
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  loadImages(product: IProduct) {
    this.productService
      .fetchImages(product.id)
      .then(result => {
        product.images = result;
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  fillDefaultSelectedStock(product: ICartProduct) {
    const warehouseId = product.warehouseId ?? 0;
    const externalWarehouseId = product.externalWarehouseId ?? 0;
    if (product.externalWarehouseId) {
      product.selectedStock = product.externalStock.find(
        x => x.warehouse.id === externalWarehouseId,
      );
    } else {
      let stockItem = product.stock.find(x => x.warehouse.id == warehouseId);

      if (!stockItem) {
        stockItem = {
          warehouse: this.warehouses.find(x => x.id == warehouseId)!,
          quantity: "0",
          prices: product?.prices ?? [],
        };
        PriceHelper.formatStockPrices([stockItem], this.appConfig.userSettings, this.i18n, true);
      }

      product.selectedStock = stockItem;
    }
    this.eventAggregator.publish(TKEvent.updateCartTotal);
  }

  loadExternalStock(product: ICartProduct) {
    this.productService
      .fetchExternalStock(product.id)
      .then(result => {
        if (Utils.isArrayEmpty(result)) result = [];
        product.externalStock = result;

        PriceHelper.formatStockPrices(
          product.externalStock,
          this.appConfig.userSettings,
          this.i18n,
        );
      })
      .catch(error => {
        console.error("Error fetching external stock:", error);
      })
      .finally(() => {
        this.fillDefaultSelectedStock(product);
      });
  }

  // #endregion

  // #region ACTIONS

  showGallery(product: IProduct) {
    $(() => {
      // If there are no images to display
      if ($(`[data-gallery="${product.id}"]`).children(".carousel-inner").children().length == 0)
        return;

      const dataGallerySelector = document.querySelector(`[data-gallery-wrapper="${product.id}"]`);
      if (dataGallerySelector) new Modal(dataGallerySelector).toggle();
    });
  }

  updateProduct(product: ICartProduct) {
    const cartProduct = {
      cartProductId: product.cartProductId ?? 0,
      foundBy: product.foundBy,
      code: product.code,
      quantity: parseInt(product.quantity.toString()),
      comment: product.comment,
      warehouseId: product.selectedStock?.warehouse?.id ?? product.warehouseId ?? 0,
      transportId: parseInt(product.transportId ?? "0"),
    } as any;

    if (product.selectedStock?.warehouse?.isExternal) {
      cartProduct.warehouseId = 0;
      cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
    }

    if (isNaN(cartProduct.quantity)) return;
    if (cartProduct.quantity < 1) {
      product.quantity = 1;
      return;
    }

    this.cartProductService
      .putExistingProduct(cartProduct)
      .then(result => {
        this.products = result.products;

        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.update")));
  }

  removeFromCart(cartProductId: number) {
    this.cartProductService
      .delete(cartProductId)
      .then(() => {
        this.products.forEach((product, index) => {
          if (product.cartProductId == cartProductId) {
            this.products.splice(index, 1);
            return;
          }
        });

        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.remove")));
  }

  // #endregion

  tryLoadImages() {
    if (!this.products || !this.options.showImages || this.imagesLoaded) return;

    this.imagesLoaded = true;
    this.products.forEach(product => {
      this.loadThumbnail(product);
      this.loadImages(product);
    });
  }

  formatPrice() {
    if (!this.products || !this.appConfig.userSettings) return;

    PriceHelper.formatProductsPrices(this.products, this.appConfig.userSettings, this.i18n, true);
  }
}
