import { IRvsOrder } from "domain/Sale/IRvsOrder";
import { I18N } from "aurelia-i18n";
import { IProduct } from "domain/Product/IProduct";
import { SearchType } from "utils/enums/SearchType";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import { LogManager, autoinject, observable, bindable } from "aurelia-framework";
import { TKEvent } from "utils/enums/TKEvent";
import { ICartProduct } from "domain/Cart/ICartProduct";
import $ from "jquery";
import { ReturnService } from "services/sale/return-service";
import { Modal } from "bootstrap";

export const log = LogManager.getLogger("app.components.nested-cart-product-list");

// Shown in template, order, invoice and return pages
@autoinject
export class NestedCartProductListCustomElement {
  @bindable @observable products: ICartProduct[];
  @bindable rvsOrder: IRvsOrder | undefined;

  @bindable options = {
    showInvoiceId: false,
    showWarehouse: false,
    showTransport: false,
    showAddToCart: true,
    showAddToReturn: false,
  };

  constructor(
    private returnService: ReturnService,
    private appConfig: AppConfig,
    private router: Router,
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.warehousesLoaded, () => this.loadWarehouseNames());
    this.eventAggregator.subscribeOnce(TKEvent.transportOptionsLoaded, () =>
      this.loadTransportNames()
    );
  }

  // #region MAIN

  productsChanged() {
    this.processResult();
    this.loadWarehouseNames();
    this.loadTransportNames();
  }

  processResult() {
    this.products.forEach(product => {
      // Fill out the quantity in cart input
      if (product.quantity === undefined) product.quantity = 1;

      // Fill out the add-to-cart quantity input
      if (product.toCartQuantity === undefined) product.toCartQuantity = "1";

      // Fill out the return quantity input
      if (product.returnQuantity === undefined) product.returnQuantity = "1";
    });
  }

  loadWarehouseNames() {
    if (!this.appConfig.warehouses) return;

    this.products.forEach(product => {
      product.warehouseName = this.getWarehouseName(product.warehouseId ?? 0);
    });
  }

  loadTransportNames() {
    if (!this.appConfig.transportOptions) return;

    this.products.forEach(product => {
      product.transportName = this.getTransportName(product.transportId ?? "0");
    });
  }

  // #endregion

  // #region ACTIONS

  showGallery(product: ICartProduct) {
    $(() => {
      // If there are no images to display
      if ($(`[data-gallery="${product.id}"]`).children(".carousel-inner").children().length == 0)
        return;

      const dataGallerySelector = document.querySelectorAll(
        `[data-gallery-wrapper="${product.id}"]`
      );
      Modal.getInstance([].slice.call(dataGallerySelector))?.show();
    });
  }

  searchProduct(target: HTMLElement, product: IProduct) {
    // Check if child element was clicked instead
    if ($(target).prop("tagName") != "TD" && $(target).prop("tagName") != "TR") return;

    this.router.navigateToRoute("searchResult", {
      query: Utils.encode(product.code),
      page: 1,
      type: SearchType.Precise,
    });
  }

  searchInvoice(invoiceId: number) {
    this.router.navigateToRoute("invoiceIndex", {
      page: 1,
      query: invoiceId,
    });
  }

  returnProduct(product: ICartProduct) {
    this.returnService
      .returnProduct({
        invoiceId: this.rvsOrder?.invoiceId ?? 0,
        rvsOrderId: this.rvsOrder?.id ?? 0,
        code: product.code,
        amount: parseInt(product.returnQuantity ?? "1"),
      })
      .then(() => {
        Utils.showSuccessToast(this.i18n.tr("components.products.messages.added-to-return"));

        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch(() => {
        Utils.showErrorToast(log, this.i18n.tr("components.products.errors.add-to-return"));
      });
  }

  // #endregion

  // #region HELPERS

  getWarehouseName(warehouseId: number): string {
    if (this.appConfig.warehouses != undefined) {
      for (const warehouse of this.appConfig.warehouses) {
        if (warehouse.id == warehouseId) return warehouse.name;
      }
    }
    return `ID: ${warehouseId}`;
  }

  getTransportName(transportId: string | number): string {
    // using `` to convert "string | number" to string
    return this.appConfig.transportOptions.get(`${transportId}`) ?? `ID: ${transportId}`;
  }

  // #endregion
}
